<template>
  <section>
    <div v-if="!loading">
      <b-row>
        <b-col md="12">
          <b-card no-body>
            <b-card-header>
              <!-- title and subtitle -->
              <div>
                <b-card-title class="mb-1">
                  {{ $t('field.revenue') }} {{ $t('dashboard.instant') }}
                </b-card-title>
                <b-card-sub-title
                  >{{ $t('dashboard.30Days') }} ($)</b-card-sub-title
                >
              </div>
              <!--/ title and subtitle -->

              <!-- badge -->
              <!--/ badge -->
            </b-card-header>

            <b-card-body>
              <vue-apex-charts
                type="line"
                height="400"
                :options="{
                  chart: {
                    zoom: {
                      enabled: false,
                    },
                    toolbar: {
                      show: false,
                    },
                  },
                  markers: {
                    strokeWidth: 7,
                    strokeOpacity: 1,
                    strokeColors: ['#0000FF', '#836AF9', '#0000FF'],
                    colors: ['#0000FF', '#836AF9', '#0000FF'],
                  },
                  colors: ['#0000FF', '#836AF9', '#0000FF'],
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: 'straight',
                  },
                  grid: {
                    xaxis: {
                      lines: {
                        show: true,
                      },
                    },
                  },
                  tooltip: {
                    custom,
                  },
                  xaxis: {
                    categories: dates,
                  },
                  yaxis: {
                    // opposite: isRtl,
                  },
                }"
                :series="[
                  {
                    data: instants,
                  },
                ]"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="d-flex justify-content-center align-items-center mb-1" v-else>
      <b-spinner label="loading" type="grow" />
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
  BCol,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Repository from '@/repositories/RepositoryFactory'
const DashboardRepository = Repository.get('dashboard')

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCol,
    BRow,
    BSpinner,
  },
  data() {
    return {
      loading: false,
      items: [],
      manuals: [],
      preOrders: [],
      instants: [],
      dates: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    custom(data) {
      return `${"<div class=''><span>"}${
        data.series[data.seriesIndex][data.dataPointIndex]
      }</span></div>`
    },
    getData() {
      this.loading = true
      DashboardRepository.month()
        .then((response) => {
          let data = response.data.data
          this.items = data

          if (this.items.instants) {
            this.instants = this.items.instants
          }
          if (this.items.preOrders) {
            this.preOrders = this.items.preOrders
          }
          if (this.items.manuals) {
            this.manuals = this.items.manuals
          }
          if (this.items.date) {
            this.dates = this.items.date
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>
