export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'mediaFile',
    sortable: false,
    sortField: '',
    label: 'field.logo',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'name',
    sortable: false,
    sortField: 'name',
    label: 'field.sportVenueName',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'like',
    sortable: false,
    sortField: 'like',
    label: 'field.like',
    stickyColumn: true,
    variant: 'light',
  },
];
