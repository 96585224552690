<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Game Revenue</b-card-title>
    </b-card-header>
    <b-card-body v-if="data.length == 0" class="statistics-body">
      Currently no games
    </b-card-body>
    <b-card-body v-else class="statistics-body">
      <b-row>
        <b-col
          v-for="(value, index) in data"
          :key="index"
          xl="3"
          sm="3"
          class="py-2"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="primary">
                <feather-icon size="24" icon="SmileIcon" />
              </b-avatar>
            </b-media-aside>
            <div>
              <h4 class="font-weight-bolder mb-0">
                ${{ kFormatter(parseFloat(value.total).toFixed(2)) }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ value.name }}
              </b-card-text>
            </div>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      default: () => [],
    },
  },

  methods: {
    kFormatter,
  },
}
</script>
