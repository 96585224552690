<template>
  <section id="dashboard-analytics">
    <div v-if="!loading">
      <b-row class="match-height">
        <b-col xl="4" md="6">
          <ecommerce-medal :data="data.transactionThisMonth" />
        </b-col>
        <b-col xl="8" md="8">
          <ecommerce-statistics :data="data" />
        </b-col>
        <b-col xl="12" md="12">
          <game-revenue :data="game" />
        </b-col>
      </b-row>
    </div>
    <apex-line-chart-vue />
  </section>
</template>

<script>
import { BCol, BRow, BSpinner } from 'bootstrap-vue'
import EcommerceMedal from './dashboard/EcommerceMedal'
import EcommerceStatistics from './dashboard/EcommerceStatistics'
import GameRevenue from './dashboard/GameRevenue.vue'
import Repository from '@/repositories/RepositoryFactory'
import ChartjsBarChart from './dashboard/ChartjsBarChart'
import ChartjsHorizontalBarChart from './dashboard/ChartjsHorizontalBarChart'
import ChartjsDoughnutChart from './dashboard/ChartjsDoughnutChart'
import LikeTable from './dashboard/LikeTable'
import ApexLineChartVue from './dashboard/ApexLineChart.vue'

const DashboardRepository = Repository.get('dashboard')

export default {
  components: {
    BCol,
    BRow,
    BSpinner,
    EcommerceStatistics,
    EcommerceMedal,
    GameRevenue,
    ChartjsBarChart,
    ChartjsHorizontalBarChart,
    ChartjsDoughnutChart,
    LikeTable,
    ApexLineChartVue,
  },
  data() {
    return {
      data: {
        transactionThisMonth: null,
      },
      game: [],
      loading: false,
    }
  },

  mounted() {
    this.getData()
    this.getGame()
  },

  methods: {
    getData() {
      this.loading = true
      DashboardRepository.index()
        .then((response) => {
          this.data = response.data.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
        .then(() => {
          this.loading = false
        })
    },
    getGame() {
      this.loading = true
      DashboardRepository.game()
        .then((response) => {
          this.game = response.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style></style>
