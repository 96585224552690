<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>{{ $t('dashboard.statistics') }}</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col xl="3" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="primary">
                <feather-icon size="24" icon="TrendingUpIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.totalTransactions }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t('dashboard.transactions') }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col xl="3" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="danger">
                <feather-icon size="24" icon="BoxIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0" v-if="data.totalRevenue">
                ${{ kFormatter(parseFloat(data.totalRevenue).toFixed(2)) }}
              </h4>
              <h4 class="font-weight-bolder mb-0" v-else>
                ${{ kFormatter(0) }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t('dashboard.revenue') }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      total: 0,
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    total: function () {
      this.total = this.data?.totalRevenueBookings?.toFixed(2)
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
