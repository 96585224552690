<template>
  <b-card :title="$t('dashboard.booking')" v-if="items">
    <!-- chart -->

    <b-card-body>
      <div v-if="!loading && items.length > 0">
        <chartjs-component-doughnut-chart
          :height="275"
          :data="data"
          :options="options"
          class="mb-3"
        />
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="index < items.length - 1 ? 'mb-1' : ''"
          class="d-flex justify-content-between"
        >
          <div class="d-flex align-items-center">
            <span class="font-weight-bold ml-75 mr-25">{{ item.name }}</span>
          </div>
          <div>
            <span>{{ item.count }}</span>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <b class="ml-75 mr-25">{{ $t('field.total') }}</b>
          </div>
          <div>
            <span>{{ total }}</span>
          </div>
        </div>
      </div>
      <div v-else-if="items.length == 0 && !loading">
        <b-img :src="notFound" fluid alt="Not Found Image"> </b-img>
      </div>
      <div class="d-flex justify-content-center align-items-center mb-1" v-else>
        <b-spinner label="loading" type="grow" />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BSpinner, BCardBody, BImg } from 'bootstrap-vue'
import ChartjsComponentDoughnutChart from './charts-components/ChartjsComponentDoughnutChart.vue'
import Repository from '@/repositories/RepositoryFactory'
const DashboardRepository = Repository.get('dashboard')

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  components: {
    ChartjsComponentDoughnutChart,
    BCard,
    BSpinner,
    BCardBody,
    BImg,
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 60,
        legend: { display: false },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[0].labels[tooltipItem.index] || ''
              const value = data.datasets[0].data[tooltipItem.index]
              const output = ` ${label} : ${value}`
              return output
            },
          },
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
        },
      },
      data: {
        datasets: [
          {
            labels: [],
            data: [],
            backgroundColor: [
              chartColors.successColorShade,
              chartColors.warningLightColor,
              chartColors.primaryColorShade,
              chartColors.yellowColor,
              chartColors.blueColor,
            ],
            borderWidth: 0,
            pointStyle: 'rectRounded',
          },
        ],
      },
      loading: false,
      items: [],
      total: 0,
      notFound: require('@/assets/images/not_found.gif'),
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      DashboardRepository.index()
        .then((response) => {
          this.data.datasets[0].labels = response.data.data.map(
            (item) => item.name
          )
          this.data.datasets[0].data = response.data.data.map(
            (item) => item.count
          )
          response.data.data.forEach((item) => {
            this.total += item.count
          })
          this.items = response.data.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>
