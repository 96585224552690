var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(!_vm.loading)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('div',[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('field.revenue'))+" "+_vm._s(_vm.$t('dashboard.instant'))+" ")]),_c('b-card-sub-title',[_vm._v(_vm._s(_vm.$t('dashboard.30Days'))+" ($)")])],1)]),_c('b-card-body',[_c('vue-apex-charts',{attrs:{"type":"line","height":"400","options":{
                chart: {
                  zoom: {
                    enabled: false,
                  },
                  toolbar: {
                    show: false,
                  },
                },
                markers: {
                  strokeWidth: 7,
                  strokeOpacity: 1,
                  strokeColors: ['#0000FF', '#836AF9', '#0000FF'],
                  colors: ['#0000FF', '#836AF9', '#0000FF'],
                },
                colors: ['#0000FF', '#836AF9', '#0000FF'],
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: 'straight',
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },
                tooltip: {
                  custom: _vm.custom,
                },
                xaxis: {
                  categories: _vm.dates,
                },
                yaxis: {
                  // opposite: isRtl,
                },
              },"series":[
                {
                  data: _vm.instants,
                } ]}})],1)],1)],1)],1)],1):_c('div',{staticClass:"d-flex justify-content-center align-items-center mb-1"},[_c('b-spinner',{attrs:{"label":"loading","type":"grow"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }