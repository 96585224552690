<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-sub-title class="mb-25"> Balance </b-card-sub-title>
        <b-card-title>${{ kFormatter(balance.toFixed(2)) }}</b-card-title>
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <div v-if="!loading">
        <chartjs-component-horizontal-bar-chart
          :height="400"
          :data="data"
          :options="options"
        />
      </div>
      <div class="d-flex justify-content-center align-items-center mb-1" v-else>
        <b-spinner label="loading" type="grow" />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BSpinner,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import ChartjsComponentHorizontalBarChart from './charts-components/ChartjsComponentHorizontalBarChart.vue'
import Repository from '@/repositories/RepositoryFactory'

const DashboardRepository = Repository.get('dashboard')

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BSpinner,
    ChartjsComponentHorizontalBarChart,
  },
  data() {
    return {
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'top',
          },
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                zeroLineColor: chartColors.grid_line_color,
                borderColor: 'transparent',
                color: chartColors.grid_line_color,
                drawTicks: false,
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                min: 0,
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },
      data: {
        labels: [
          this.$t('days.monday'),
          this.$t('days.tuesday'),
          this.$t('days.wednesday'),
          this.$t('days.thursday'),
          this.$t('days.Friday'),
          this.$t('days.satursday'),
          this.$t('days.sunday'),
        ],
        datasets: [
          {
            data: [],
            backgroundColor: chartColors.successColorShade,
            borderColor: 'transparent',
            barThickness: 15,
          },
        ],
      },
      balance: 0,
      loading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      DashboardRepository.index()
        .then((response) => {
          this.data.datasets[0].data = response.data.data.days
          this.loading = false
          this.balance = response.data.data.total
        })
        .catch(() => {
          this.loading = false
        })
        .then(() => {
          this.loading = false
        })
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
