<template>
  <div>
    <load-profile></load-profile>
    <b-card no-body class="mb-0">
      <b-card-header>
        <b-card-title>{{ $t('dashboard.top_like') }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <n-table :fields="fields" :items="items" :busy="loading" :total="10">
          <template #cell(mediaFile)="data">
            <b-img-lazy
              :src="data.item.mediaFile"
              :width="100"
              :height="100"
              :blank-src="defaultImage"
              v-if="data.item.mediaFile"
            />
            <b-img-lazy
              :src="defaultImage"
              :width="100"
              :height="100"
              :blank-src="defaultImage"
              v-else
            />
          </template>
        </n-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BImgLazy,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Repository from '@/repositories/RepositoryFactory'
import NPagination from '@/components/NPagination'
import NSearchContainer from '@/components/NSearchContainer'
import NSearchInput from '@/components/NSearchInput'
import NButtonDelete from '@/components/NButtonDelete'
import NTable from '@/components/NTable'
import tableFields from './tableFields'
import { avatarText } from '@core/utils/filter'

const DashboardRepository = Repository.get('dashboard')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BImgLazy,
    BCardBody,
    BCardHeader,
    BCardTitle,
  },
  watch: {
    perPage(value) {
      this.list(1)
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage
      },
      set(value) {
        this.$store.commit('pagination/SET_PER_PAGE', value)
      },
    },
    fields() {
      let fieldKeys = tableFields.map((item) => {
        if (item.key == 'name') {
          item.sortField = `name_${this.$i18n.locale}`
        }
        return item
      })
      return fieldKeys
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      defaultImage: require('@/assets/images/placeholder.png'),
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        search: this.$route.query.search || null,
        limit: this.$store.state.pagination.perPage || 10,
      },
      avatarText,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      const params = {
        ...this.params,
      }
      DashboardRepository.index(params)
        .then((response) => {
          const data = response.data.data
          this.items = [...data]
          this.total = data.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
  setup() {
    let keyFields = [...tableFields]
    return {
      keyFields,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
